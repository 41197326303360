import moment from "moment";
import XLSX from "xlsx";

const titleCase = (str) => {
  const string = str.toLowerCase().split(" ");
  const retVal = string.map((item) => {
    return item.charAt(0).toUpperCase() + item.slice(1);
  });

  return retVal.join(" ");
};
const getRowData = (
  d,
  headers,
  headersReWrite,
  canReWriteHeaders,
  row = [],
  headerList = null
) => {
  if (canReWriteHeaders) {
    headers.forEach((h) => {
      if (h !== "__typename") {
        if (typeof d[h] === "object" && d[h]) {
          const rowHeaders = Object.keys(d[h]);
          const newHeader = `${headerList ? `${headerList}.${h}` : h}`;
          getRowData(
            d[h],
            rowHeaders,
            headersReWrite,
            canReWriteHeaders,
            row,
            newHeader
          );
        } else {
          const newHeader = `${headerList ? `${headerList}.${h}` : h}`;
          const rowData = d[h] || "";
          row.push(rowData);
          headersReWrite.push(newHeader);
        }
      }
    });
  } else {
    headers.forEach((h) => {
      if (h !== "__typename") {
        if (typeof d[h] === "object" && d[h]) {
          const rowHeaders = Object.keys(d[h]);
          getRowData(d[h], rowHeaders, headersReWrite, canReWriteHeaders, row);
        } else {
          const rowData = d[h] || "";
          row.push(rowData);
        }
      }
    });
  }
  return row;
};
/**
 *
 * @param {array} downloadAccessor
 * @param {Boolean} multiSheet
 * @returns {array} export data
 */
export const getExportArrayUtil = (
  downloadAccessor = [],
  multiSheet = false,
  getSheetName = null
) => {
  // Converting json into a string array.
  const exportRes = [];
  let headers = [];
  let col = [];
  const sheets = [];

  if (downloadAccessor.length > 0) {
    if (multiSheet) {
      if (!getSheetName) {
        throw Error(
          "getSheetName is not defined. This is required when multiSheet is enabled"
        );
      }
      downloadAccessor.forEach((d) => {
        const SheetHeaders = Object.keys(d);
        const sheet = [];
        SheetHeaders.forEach((header) => {
          col = [];
          if (d[header] && header !== "__typename") {
            if (typeof d[header] === "object") {
              if (d[header].length) {
                headers = Object.keys(d[header][0]);
                const headersReWrite = [];
                d[header].forEach((dH) => {
                  const row = getRowData(
                    dH,
                    headers,
                    headersReWrite,
                    headersReWrite.length === 0
                  );
                  col.push(row);
                });
                const result = [];
                result.push([header]);
                result.push(headersReWrite);
                sheet.push(result.concat(col));
                sheet.push([""]);
              } else {
                headers = Object.keys(d[header]);
                const headersReWrite = [];
                const row = getRowData(
                  d[header],
                  headers,
                  headersReWrite,
                  headersReWrite.length === 0
                );
                const result = [];
                result.push([header]);
                result.push(headersReWrite);
                result.push(row);
                result.push("");
                sheet.push(result);
              }
            } else {
              // This is to cater if the key is not an array or an object.
              const result = [];
              result.push([header]);
              result.push(d[header]);
              result.push("");
              sheet.push(result);
            }
          } else if (header !== "__typename") {
            sheet.push([[header]]);
            sheet.push([["No Data To Display"]]);
            sheet.push([[""]]);
          }
        });

        let concattedSheet = [];

        sheet.forEach((s) => {
          concattedSheet = concattedSheet.concat(s);
        });

        sheets.push({
          data: concattedSheet,
          name: getSheetName(d),
        });
      });
      return sheets;
    }
    headers = Object.keys(downloadAccessor[0]);
    const headersReWrite = [];
    downloadAccessor.forEach((d) => {
      const row = getRowData(
        d,
        headers,
        headersReWrite,
        headersReWrite.length === 0
      );
      col.push(row);
    });

    exportRes.push(headersReWrite);
    return exportRes.concat(col);
  }
  return [];
};
export const refetchData = ({
  variables,
  refetch,
  pageIndex,
  pageSize,
  sortBy,
  type,
}) => {
  if (type === "prisma") {
    const tempVariables = {
      ...variables,
      first: pageSize,
      skip: pageIndex * pageSize,
    };
    if (sortBy.length > 0) {
      tempVariables.order = `${sortBy[0].id}_${
        sortBy[0].desc ? "DESC" : "ASC"
      }`;
    }
    refetch({
      variables: tempVariables,
    });
  }
  if (type === "custom") {
    const pagination = {
      resultCount: pageSize,
      page: pageIndex,
    };
    if (sortBy.length > 0) {
      if (sortBy[0].desc) {
        pagination.isOrderAscending = true;
      }
      pagination.orderByColumnName = sortBy[0].id;
    }
    let tempVariables = {};

    if (variables.pagination) {
      tempVariables = {
        ...variables,
        pagination: {
          ...variables.pagination,
          ...pagination,
        },
      };
    } else {
      tempVariables = {
        ...variables,
        pagination,
      };
    }

    refetch({
      variables: tempVariables,
    });
  }
};
/**
 * Build the data needed for the pagination of the table.
 * @param {PaginationData} data - Information needed to build pagination data.
 */
export const getPaginationData = ({
  type,
  dataCount,
  maxPageCount,
  pageIndex,
  pageSize,
}) => {
  if (type === "custom") {
    return {
      canNext: pageIndex + 1 !== maxPageCount,
      canPrevious: pageIndex !== 0,
      paginationText: `Showing page ${pageIndex + 1} of ${maxPageCount}`,
      lastPage: maxPageCount - 1,
    };
  }
  const currentDataCount = Math.min(dataCount, (pageIndex + 1) * pageSize);
  let lastPage = Math.floor(dataCount / pageSize);
  if (lastPage * pageSize === dataCount) {
    lastPage -= 1;
  }
  return {
    canNext: (pageIndex + 1) * pageSize < dataCount,
    canPrevious: pageIndex !== 0,
    paginationText: `Showing ${
      dataCount === 0 ? 0 : pageIndex * pageSize + 1
    } - ${currentDataCount} of ${dataCount}`,
    lastPage,
  };
};
const getFilterData = (filterInfo, filterData, type = "prisma") => {
  let retVal = null;
  switch (filterInfo.type) {
    case "daterange":
      if (filterData.startDate && filterData.endDate) {
        retVal = {};
        const startDate = filterData.startDate
          .utcOffset(0)
          .set({
            hour: 0,
            minute: 0,
            second: 1,
            millisecond: 0,
          })
          .toISOString();
        const endDate = filterData.endDate
          .utcOffset(0)
          .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 0,
          })
          .toISOString();
        if (type === "custom") {
          retVal = {
            pagination: {
              fromDateTime: startDate,
              toDateTime: endDate,
            },
          };
          return retVal;
        }
        retVal[`${filterInfo.accessor}_gte`] = startDate;
        retVal[`${filterInfo.accessor}_lte`] = endDate;
        return retVal;
      }
      return retVal;
    case "datetimerange":
      if (filterData.startDate && filterData.endDate) {
        retVal = {};
        const { startDate, endDate } = filterData;
        if (type === "custom") {
          retVal = {
            pagination: {
              fromDateTime: startDate,
              toDateTime: endDate,
            },
          };
          return retVal;
        }
        retVal[`${filterInfo.accessor}_gte`] = startDate;
        retVal[`${filterInfo.accessor}_lte`] = endDate;
        return retVal;
      }
      return retVal;
    case "select":
      if (filterData.value) {
        retVal = {};
        let { value } = filterData;
        if (filterData.value === "true" || filterData.value === "false") {
          value = filterData.value === "true";
        }
        retVal[filterInfo.accessor] = value;
      }
      return retVal;
    case "multiselect":
      if (filterData.value) {
        retVal = {};
        const { value } = filterData;
        if (value.length > 0) {
          retVal[filterInfo.accessor] = value.map((val) => val.value);
        }
      }
      return retVal;
    default:
      return null;
  }
};
export const getSearchData = (headers, value) => {
  const retVal = [];
  headers.forEach((h) => {
    if (h.search) {
      retVal.push({ [`${h.accessor}_contains`]: value });
      retVal.push({ [`${h.accessor}_contains`]: value.toLowerCase() });
      retVal.push({ [`${h.accessor}_contains`]: value.toUpperCase() });
      retVal.push({ [`${h.accessor}_contains`]: titleCase(value) });
    }
  });
  console.log('***')
  return retVal;
};
export const downloadXLSX = (
  data,
  downloadName,
  multiSheet,
  downloadComplete
) => {
  const wb = XLSX.utils.book_new();
  if (multiSheet) {
    data.forEach((sheet) => {
      const ws = XLSX.utils.aoa_to_sheet(sheet.data);
      XLSX.utils.book_append_sheet(wb, ws, sheet.name);
    });
  } else {
    const ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `${downloadName}${moment().format("YYYYMMDD")}`
    );
  }
  XLSX.writeFile(wb, `${downloadName}-${moment().format("YYYYMMDD")}.xlsx`);
  if (downloadComplete) {
    downloadComplete();
  }
};
export const getApplyFilter = (
  filterValues,
  filters,
  variables,
  type,
  filterIndex
) => {
  const getQueryVariables = JSON.parse(JSON.stringify(variables));
  let newFilters = {};
  let addedFilters = false;
  const retVal = {};
  if (type === "prisma") {
    filters.forEach((f) => {
      let filter;
      if (f.customFilter) {
        filter = f.customFilter(filterValues[f.accessor]);
      } else {
        filter = getFilterData(f, filterValues[f.accessor]);
      }
      if (filter) {
        newFilters = {
          ...newFilters,
          ...filter,
        };
        addedFilters = true;
      }
    });

    if (addedFilters) {
      if (filterIndex === -1) {
        retVal.index = getQueryVariables.where.AND.length;
        getQueryVariables.where.AND.push({
          AND: newFilters,
        });
      } else {
        getQueryVariables.where.AND[filterIndex].AND = newFilters;
      }
      retVal.variables = getQueryVariables;
      return retVal;
    }
  } else {
    let pagination = false;
    filters.forEach((f) => {
      let filter;
      if (f.customFilter) {
        filter = f.customFilter(filterValues[f.accessor]);
      } else {
        filter = getFilterData(f, filterValues[f.accessor], type);
      }
      if (filter) {
        if (filter.pagination) {
          pagination = {
            ...filter.pagination,
          };
        } else {
          newFilters = {
            ...newFilters,
            ...filter,
          };
        }
        addedFilters = true;
      }
    });

    if (addedFilters) {
      if (type === "prisma") {
        retVal.variables = newFilters;
      } else {
        retVal.variables = {
          filter: newFilters,
          pagination: {
            ...getQueryVariables.pagination,
            ...pagination,
          },
        };
      }
      return retVal;
    }
  }
  return false;
};
export default {
  refetchData,
  getPaginationData,
  getSearchData,
  downloadXLSX,
  getExportArrayUtil,
};
/**
 * @typedef PaginationData
 * @property {string} type - The type of table we need to render pagination for.
 * @property {number} maxPageCount - Custom. The max amount of pages that there
 * are for the page size.
 * @property {number} dataCount - Prisma. The total amount of rows that the table contains.
 * @property {number} pageSize - Both. The total number of rows to show per page.
 * @property {number} pageIndex - Both. What page is the user currently viewing.
 */
