import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FiCheck, FiX } from "react-icons/fi";
import { GoPrimitiveDot } from "react-icons/go";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../utils/context";
import PageHeader from "../../components/PageHeader/PageHeader.component";
import { H2 } from "../../components/Typography/Typography.component";
import BorderInput from "../../components/Form/BorderInput/BorderInput.component";
import Error from "../../components/Error/ErrorMessage.component";
import Button from "../../components/Button/Button.component";
import BorderSelect from "../../components/Form/BorderSelect/BorderSelect.component";
import Table, { TableIcons } from "../../components/Table/Table.component";
import {
  SmallImageHolder,
  SmallImage,
  CompanyHolder,
  CompanyName,
} from "./Communication.style";
// GQL
import {
  GET_STAFF_PROFILES,

} from "../../graphql/queries/staff.query";
import { Row, Column } from "../../components/Grid/Grid.component";
import { Span } from "../../components/Table/components/Typography/Typography.component";
import { SEND_PUSH_NOTIFICATION } from "../../graphql/mutations/sendPushnotifcation.mutation";
import Swal from "sweetalert2";

const Communication = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const { register, errors, watch, setValue } = useForm();
  const [recipients, setRecipients] = useState([]);
  const [skillFilter, setSkillfilter] = useState([]);
  const [sportCategoriesFilter, setSportCategoriesFilter] = useState([]);
  const [positionLookingForFilter, setPositionLookingForFilter] = useState([]);
  const title = watch("title");
  const body = watch("body");
  const [sendPushnotification] = useMutation(SEND_PUSH_NOTIFICATION);

  const [refetch, { data, loading, error }] = useLazyQuery(GET_STAFF_PROFILES, {
    variables: { order: "createdAt_DESC" },
    fetchPolicy: "no-cache",
  });

  const [variables, setVariables] = useState({
    where: { AND: [{ NOT: { staff: null } }] },
  });

  useEffect(() => {
    setActiveSideNav("communication");
    setPageTitle("Communication");
  });

  const getColor = (value) => {
    if (value) {
      return "#FF003A";
    }
    return "#39C568";
  };

  useEffect(() => {
    setActiveSideNav("communication");
    setPageTitle("Communication");
  });

  useEffect(() => {


    if (data) {
      const skillFilterArray = [];
      const sportCategoriesFilterArray = [];
      const roleLookingFilterArray = [];


      data.appUsers.forEach(el => {
        el.staff.skills.forEach(skill => {
          skillFilterArray.push({ value: skill.id, label: skill.title })
        })
        el.staff.sportCategories.forEach(category => {
          sportCategoriesFilterArray.push({ value: category.id, label: category.title })
        })
        el.staff.positionTypeLookingFor.forEach(role => {
          roleLookingFilterArray.push({ value: role.id, label: role.title })
        })
      });

      setSportCategoriesFilter(sportCategoriesFilterArray.sort((a, b) => ((a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0)).filter((v, i, a) => a.findIndex(t => (t.label === v.label)) === i))
      setPositionLookingForFilter(roleLookingFilterArray.sort((a, b) => ((a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0)).filter((v, i, a) => a.findIndex(t => (t.label === v.label)) === i))
      setSkillfilter(skillFilterArray.sort((a, b) => ((a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0)).filter((v, i, a) => a.findIndex(t => (t.label === v.label)) === i))
    }


  }, [data])
  ///***DATA */
  const HeaderInfo = [
    {
      Header: "Name",
      accessor: "firstName",
      id: "firstName",
      search: true
    },
    {
      Header: "Surname",
      accessor: "lastName",
      id: "lastName",
      search: true
    },
    {
      Header: "Can receive",
      accessor: "canReceive",
      id: "canReceive",
      Cell: ({ row }) => {
        return (
          <TableIcons>
            <GoPrimitiveDot
              style={{
                fontSize: "25px",
                color: getColor(!row.original.pushNotification || !row.original.pushToken || row.original.pushToken === 'unset'),
              }}
            />
            <span>{!row.original.pushNotification || !row.original.pushToken || row.original.pushToken === 'unset' ? "no" : "yes"}</span>
          </TableIcons>
        )
      },
    },
    {
      Header: "Status",
      accessor: "suspended",
      id: "suspended",
      Cell: ({ row }) => (
        <TableIcons>
          <GoPrimitiveDot
            style={{
              fontSize: "25px",
              color: getColor(row.original.suspended),
            }}
          />
          <span>{row.original.suspended ? "suspended" : "active"}</span>
        </TableIcons>
      ),
    },
    {
      Header: "Subscription",
      accessor: "subscriptionId",
      id: "subscriptionId",
      Cell: ({ row }) => {
        return (
          <TableIcons>
            <GoPrimitiveDot
              style={{
                fontSize: "25px",
                color: getColor(!row.original.staff.subscriptionId),
              }}
            />
            <span>
              {!row.original.staff.subscriptionId ? "None" : "Active"}
            </span>
          </TableIcons>
        );
      },
    },
    {
      Header: "Add to list",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          {
            row.original.pushToken && row.original.pushToken !== 'unset' && recipients.filter(el => el.pushToken === row.original.pushToken).length === 0 &&
            <FiCheck
              onClick={() => {
                const oldRecipients = [...recipients]
                oldRecipients.push(row.original)
                setRecipients(oldRecipients)
              }}
              style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            />
          }
          {
            recipients.filter(el => el.pushToken === row.original.pushToken).length > 0 &&
            <FiX
              onClick={() => {
                const oldRecipients = recipients.filter(el => el.pushToken !== row.original.pushToken)
                setRecipients(oldRecipients)
              }}
              style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            />
          }
        </TableIcons>
      ),
    },
  ];
  console.log(title, body)

  const filters = [
    {
      header: "Sport Categories:",
      accessor: "sportCategories",
      placeholder: "Please select a Sport Category",
      type: "multiselect",
      options: sportCategoriesFilter,
      customFilter: (sportCategories) => {
        let retVal = null;
        if (sportCategories.value.length) {
          retVal = {};
          retVal.staff = {
            sportCategories_some: { OR: sportCategories.value.map(el => ({ id: el.value })) }
          };
        }

        return retVal;
      },

    },
    {
      header: "Skills:",
      accessor: "skill",
      placeholder: "Please select a Skill",
      type: "multiselect",
      options: skillFilter,
      customFilter: (skill) => {
        let retVal = null;
        if (skill.value.length) {
          retVal = {};
          retVal.staff = {
            skills_some: { OR: skill.value.map(el => ({ id: el.value })) }
          };
        }

        return retVal;
      },
    },
    {
      header: "Roles Looking for:",
      accessor: "positionTypeLookingFor",
      placeholder: "Please select a role",
      type: "multiselect",
      options: positionLookingForFilter,
      customFilter: (positionTypeLookingFor) => {
        let retVal = null;
        if (positionTypeLookingFor.value.length) {
          retVal = {};
          retVal.staff = {
            positionTypeLookingFor_some: { OR: positionTypeLookingFor.value.map(el => ({ id: el.value })) }
          };
        }

        return retVal;
      },
    },
  ];


  return (
    <>
      <PageHeader title="Send push notification" />
      <Row>
        <Column width="70%">
          <H2>Push notification</H2>
          <BorderInput
            header="Notification Title"
            name="title"
            label=""
            type="text"
            register={register}
            required={{ required: "please enter a Sport Category Name" }}
            error={errors.title && <Error message={errors.title.message} />}
          />
          <BorderInput
            header="Notification body"
            name="body"
            label=""
            type="text"
            multiLine
            register={register}
            required={{ required: "please enter a Sport Category Name" }}
            error={errors.title && <Error message={errors.title.message} />}
          />

          <H2 style={{ marginTop: 15 }}>Push notification recipients</H2>
          <div style={{ width: 550 }}>
            <Row>
              <Button
                width="100%"
                onClick={() => {
                  const list = [...data.appUsers, ...recipients]
                  setRecipients(list)
                }}
                type="button"
                kind="secondary"
                value="Add all in table"
                disabled={!data}
              />
              <div style={{ width: 50 }} />
              <Button
                width="100%"
                onClick={() => {
                  setRecipients([])
                }}
                type="button"
                kind="secondary"
                value="Clear all"
                disabled={!data}
              />
            </Row>
          </div>

          {/* <BorderSelect
            header="App user Type"
            name="userType"
            label="Please select a app user group"
            register={register}
            onChange={() => { }}
            options={[
              { value: "All", text: "All" },
              { value: "STAFF", text: "Staff" },
              { value: "EMPLOYER", text: "Employer" },
            ]}
            // icon={
            //   <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            // }
            required={{
              required: "please enter a Sport Category ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
          // error={errors.id && <Error message={errors.id.message} />}
          /> */}
          <Table
            columns={HeaderInfo}
            data={(data && data.appUsers) || []}
            loading={loading}
            error={error}
            refetch={refetch}
            variables={variables}
            dataCount={(data && data.appUsersCount.length) || 0}
            pageCount={5}
            setVariables={setVariables}
            tableTitle="Recipient Staff"
            tableOptions={["search", "filter"]}
            filters={filters}
          />
        </Column>
        <Column width="30%">
          <H2 style={{ marginTop: 15 }}>Recipients Selected({recipients.length})</H2>
          <div style={{ maxHeight: 150, overflow: 'scroll' }}>
            {recipients.map(el => (<div>
              <Span>{el.firstName} {el.lastName}</Span>
            </div>))}
          </div>
          <Button
            width="100%"
            onClick={() => {
              sendPushnotification({variables:{
                data:{
                  title,
                  body,
                  recipients: recipients.map(el=>el.pushToken)
                }
              }}).then(res=>{
                Swal.fire(
                  "Sent!",
                  "Your message has been sent",
                  "success"
                ).then(() => {
                  setRecipients([]);
                  setValue("title", '');
                  setValue("body", '');
                });
              }).catch(err=>{
                console.log('broken')
              })

             }}
            type="button"
            kind="secondary"
            value="Submit & Send"
            disabled={recipients.length === 0 
              || !body 
              || !title}
          />
        </Column>
      </Row>
    </>
  );
};

export default Communication;
